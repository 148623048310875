import React from 'react';
import MDTypography from '../../ui-kit/components/MDTypography';

const Footer = ({}): JSX.Element => {
  return (
   
    <div className='continer-fluid'>
    <MDTypography>&copy; 2025 Chat | Developed by <a href='www.aimloperations.com'>AI ML Operations, LLC</a></MDTypography>
    </div>  

  );
};

export default Footer;