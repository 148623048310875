import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './llm-fe/contexts/AuthContext';
import { AccountProvider } from './llm-fe/contexts/AccountContext';
import { WebSocketProvider } from './llm-fe/contexts/WebSocketContext';
import { MaterialUIControllerProvider } from './llm-fe/ui-kit/context';
import { ConversationProvider } from './llm-fe/contexts/ConversationContext';
import { MessageProvider } from './llm-fe/contexts/MessageContext';
import { PreferenceProvider } from './llm-fe/contexts/PreferencesContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AccountProvider>
          <WebSocketProvider>
            <PreferenceProvider>
              <ConversationProvider>
                <MessageProvider>
                  <MaterialUIControllerProvider>
                    <App />
                  </MaterialUIControllerProvider>
                </MessageProvider>
              </ConversationProvider>
            </PreferenceProvider>
          </WebSocketProvider>
        </AccountProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
