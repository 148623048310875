import { useContext, useEffect, useRef, useState } from "react"
import { WebSocketContext } from "../../contexts/WebSocketContext"
import { AccountContext } from "../../contexts/AccountContext"
import Markdown from "markdown-to-jsx"
import { Card, CardContent, CircularProgress } from "@mui/material"
import MDTypography from "../../ui-kit/components/MDTypography"
import styled from 'styled-components';
// import { CodeBlock } from "react-code-blocks"
// import CustomCodeBlock from "../CustomPreBlock/CustomPreBlock"
// import CustomPreBlock from "../CustomPreBlock/CustomPreBlock"

const StyleDiv = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;

  h1 {
    color: #333;
    font-size: 24px;
  }

  span {
    color: #666;
    font-size: 16px;
  }
`;

type ConversationDetailCardProps = {
    message: string
    user_created: boolean

}

const ConversationDetailCard = ({message, user_created}: ConversationDetailCardProps): JSX.Element => {
    const type = user_created ? 'info' : 'dark'
    if(user_created){

    }
    const text_align = user_created ? 'right' : 'left'
    if (message.length === 0){
        return(
            <Card sx={{margin: '0.25rem'}}>
                <CardContent className='card-body-small text-dark ' style={{textAlign: `right`, marginRight:  '1rem', marginLeft:  '1rem', marginTop: '1rem', marginBottom: '1rem'}}>
                    <CircularProgress color="inherit"/>
                </CardContent>
            </Card>
        )
    }else{
        const newMessage: string = message.replace("```", "\n```\n");        
    
        return (

        
        <Card sx={{margin: '0.25rem'}}>
            <CardContent sx={{textAlign: `${text_align}`, marginRight:  '1rem', marginLeft:  '1rem', marginTop: '1rem', marginBottom: '1rem'}}>
                <Markdown 
                    className='display-linebreak' 
                    style={{whiteSpace: "pre-line"}}
                    color='#F000000'
                >{newMessage}</Markdown>
            </CardContent>
        </Card>
        )

    }
    
}

export default ConversationDetailCard;