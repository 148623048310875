import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Conversation, ConversationType } from "../data";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../../axiosApi";
import { AuthContext } from "./AuthContext";
import { PreferenceContext } from "./PreferencesContext";

type ConversationProviderProps ={
    children? : ReactNode;
}

type IConversationContext = {
    conversations: Conversation[];
    setConversations: (conversations: Conversation[]) => void;
    selectedConversation: number | undefined;
    setSelectedConversation: (conversation_id: number | undefined) => void;
    deleteConversation: (conversation_id: number | undefined) => void;
}

const initialValues = {
    conversations: [],
    setConversations: () => {},
    selectedConversation: undefined,
    setSelectedConversation: () => {},
    deleteConversation: () => {},
}

const ConversationContext = createContext<IConversationContext>(initialValues);

const ConversationProvider = ({children}: ConversationProviderProps) => {
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<number | undefined>(undefined);
    const { authenticated, loading } = useContext(AuthContext);
    const {preferencesUpdated} = useContext(PreferenceContext);

    function deleteConversation(conversation_id: number | undefined){
        //console.log(`detele ${conversation_id}`)
        
        try{
            axiosInstance.delete(`conversation_details`, {
                data: {'conversation_id':conversation_id}
            })
            // remove it from the list now
            setConversations(conversations.filter((conversation) => conversation.id !== conversation_id));

            // if it the current selected one, update the selected conversation
            if (selectedConversation === conversation_id){
                setSelectedConversation(undefined)   
            }

            

            

        }catch{

        }
    }

    async function GetConversations(){
        const {data, }: AxiosResponse<ConversationType[]> = await axiosInstance.get(`conversations`)
        setConversations(data.map((item) => new Conversation({
            id: item.id,
            title: item.title
        })))        
    }

    useEffect(() => {
        if(!loading && authenticated){
            GetConversations();
        }
    }, [selectedConversation, authenticated, preferencesUpdated])
    return(
        <ConversationContext.Provider value={{conversations, setConversations, selectedConversation, setSelectedConversation, deleteConversation}}>
            {children}
        </ConversationContext.Provider>
    )
}

export { ConversationContext, ConversationProvider }