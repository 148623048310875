import { Card, CardContent, Divider, Switch } from "@mui/material";
import MDTypography from "../../ui-kit/components/MDTypography";
import MDBox from "../../ui-kit/components/MDBox";
import {
  useMaterialUIController,
  setDarkMode,
} from "../../ui-kit/context";
import { useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../../../axiosApi";
import { PreferencesType } from "../../data";
import { PreferenceContext } from "../../contexts/PreferencesContext";

type PreferencesValues = {
  order: boolean;
}

const ThemeCard = ({}): JSX.Element => {
    const [controller, dispatch] = useMaterialUIController();
    const {
      darkMode,
    } = controller;

    const [order, setOrder] = useState<boolean>(true);
    const {updatePreferences} = useContext(PreferenceContext);

    const handleConversationOrder = async ({order}: PreferencesValues): Promise<void> => {
      try{
          const {data, }: AxiosResponse<PreferencesType> = await axiosInstance.post('/conversation_preferences', {
              order: order
          })
          updatePreferences();
      }catch{

      }
          

  }

    async function getConversationOrder(){
      try{
        const {data, }: AxiosResponse<PreferencesType> = await axiosInstance.get(`/conversation_preferences`);
        setOrder(data.order);
  
  
      }catch(error){
        console.log(error)
      }
    }
  
    useEffect(()=>{
      getConversationOrder();
    }, [])
    
    const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
    const isThemeReady=false;
    return(
        <Card sx={{ mb: 1}}>
            <CardContent>
              <MDTypography variant="h3">
                Account Preferences

              </MDTypography>
            </CardContent>
            <Divider />
            <CardContent>
              {isThemeReady ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
              <MDTypography variant="h6">Light / Dark</MDTypography>
              <Switch checked={darkMode} onChange={handleDarkMode} disabled={false} />
            </MDBox>
              ) : (
                <></>
              )}
            <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
              <MDTypography variant="h6">Converastion Order</MDTypography>
              <Switch checked={order} onChange={() => {
                setOrder(!order);
                handleConversationOrder({order: !order})
              }} />
            </MDBox>
            </CardContent>
        </Card>
    )
}

export default ThemeCard;
