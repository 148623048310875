import { Dispatch, SetStateAction } from "react";
import { Conversation, ConversationType } from "../../data";
import { Box, Button, ButtonBase, Card, CardContent, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import MDTypography from "../../ui-kit/components/MDTypography";

type ConversationCardProps = {
    title: string,
    conversation_id: number | undefined,
    setSelectConversation: Dispatch<SetStateAction<number | undefined>>; // state function to set the selected conversation
    deleteConversation: (conversation_id: number | undefined)=>void;
    selectedConversation: number | undefined;
};

const ConversationCard = ({title, conversation_id, setSelectConversation, deleteConversation, selectedConversation}: ConversationCardProps): JSX.Element => {
    const backgroundColor = selectedConversation===conversation_id ? '#ffffff' : 'lightgray';
    return (
        // <Row>

        
        // <ButtonGroup>
        //     <ButtonBase onClick={() => {setSelectConversation(conversation_id)}} style={{width: "80%", margin:'1px'}}>
        //          <CardContent >{title}</CardContent>
        //      </ButtonBase>
        //      <IconButton aria-label="delete ${conversation_id}" onClick={() => deleteConversation(conversation_id)}>
        //          <Delete />
        //      </IconButton>
        // </ButtonGroup>
        // </Row>
        <Row color="red">
            <Col className="col-8">
            
        
        <MDTypography
          onClick={() => {setSelectConversation(conversation_id)}}
          key={conversation_id}
          color={'white'}
          fontWeight={selectedConversation===conversation_id ? 'bold' : 'regular'}
          display="block"
          variant="caption"
          textTransform="uppercase"
          mt={2}
          mb={1}
          ml={1}
          noWrap
        >
          {title}
        </MDTypography>

        
        
        </Col>
        <Col className="col-4">
            <IconButton 
                aria-label="delete ${conversation_id}" 
                onClick={() => deleteConversation(conversation_id)}
                
                
                
                
            >
                <Delete sx={{color:backgroundColor}}/>
            </IconButton>
        </Col>
        </Row>

        // <Card style={{width: "100%", margin:'0px'}}
        // sx={{
        //     backgroundColor: selectedConversation === conversation_id ? 'lightblue' : 'inherit',
        //   }}
        // >
        //     <div className="row">
        //     <div className='col-10'>
        //         <ButtonBase onClick={() => {console.log(conversation_id); setSelectConversation(conversation_id)}} style={{width: "100%", margin:'1px'}}>
        //             <CardContent style={{width: "100%", margin:'0px'}}>{title}</CardContent>
        //         </ButtonBase>

        //     </div>
        //     <div className='col-1'>
        //         <IconButton aria-label="delete ${conversation_id}" onClick={() => deleteConversation(conversation_id)}>
        //         <Delete />
        //     </IconButton>
                
        //     </div>
        //     </div>
            
            
        // </Card>
    );
}

export default ConversationCard;