import { createContext, ReactNode, useState } from "react";

type PrefernceProviderProps ={
    children? : ReactNode;
}

type IPreferenceContext = {
    updatePreferences: () => void;
    preferencesUpdated: boolean;
}

const initialValues = {
    updatePreferences: () => {},
    preferencesUpdated: true,
}

const PreferenceContext = createContext<IPreferenceContext>(initialValues);

const PreferenceProvider = ({children}: PrefernceProviderProps) => {
    const [preferencesUpdated, setPreferencesUpdated] = useState<boolean>(true);

    function updatePreferences(){
        setPreferencesUpdated(!preferencesUpdated);
    }

    return (
        <PreferenceContext.Provider value={{updatePreferences, preferencesUpdated}}>
            {children}
        </PreferenceContext.Provider>
    )
}

export { PreferenceContext, PreferenceProvider }