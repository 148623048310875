import { useContext, useEffect, useState } from "react"
import Footer from "../../components/Footer/Footer"
import Header2 from "../../components/Header2/Header2"
import PageWrapperLayout from "../../components/PageWrapperLayout/PageWrapperLayout"
import MDBox from "../../ui-kit/components/MDBox"
import { AccountContext } from "../../contexts/AccountContext"
import { Card, CardContent, Divider } from "@mui/material"
import MDTypography from "../../ui-kit/components/MDTypography"
import { Area, Bar, BarChart, ComposedChart, Legend, Line, LineChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { axiosInstance } from "../../../axiosApi"
import { AxiosResponse } from "axios"
import { AdminAnalytics, AdminAnalyticsType, CompanyUsageAnalytics, CompanyUsageAnalyticsType, UserConversationAnalytics, UserConvesationAnalyticsType, UserPromptAnalytics, UserPromptAnalyticsType } from "../../data"

const UserPromptAnalyticsCard = ({}): JSX.Element => {
    const [data, setData] = useState<UserPromptAnalytics[]>([])
    
    async function getUserPromptAnalytics(){
        try{
            const {data, }: AxiosResponse<UserPromptAnalyticsType[]> = await axiosInstance.get(`/analytics/user_prompts/`);

            setData(data)
                  


        }catch(error){
            
        }
    }
    useEffect(()=>{
        getUserPromptAnalytics();
    }, [])
    return (
        <Card sx={{ mb: 1}}>
            <CardContent>
                <MDTypography variant="h3">
                    Prompt Usage
                </MDTypography>

            </CardContent>
            <Divider />
            <CardContent>
                <div style={{ width: "100%", height: 600}} >
                <ResponsiveContainer>
                    <BarChart data={data}>
                        <XAxis />
                        <YAxis />
                        <Legend />
                        <Bar dataKey="you" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />}/>
                        <Bar dataKey="others" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />}/>
                        <Bar dataKey="all" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />}/>
                    </BarChart>
                </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}

const UserConversationAnalyticsCard = ({}): JSX.Element => {
    const [data, setData] = useState<UserConversationAnalytics[]>([])
    async function getUserConversationAnalytics(){
        try{
            const {data, }: AxiosResponse<UserConvesationAnalyticsType[]> = await axiosInstance.get(`/analytics/user_conversations/`);

            setData(data)
                  


        }catch(error){
            
        }
    }
    useEffect(()=>{
        getUserConversationAnalytics();
    }, [])

    return (
        <Card sx={{ mb: 1}}>
            <CardContent>
                <MDTypography variant="h3">
                    Conversation Usage
                </MDTypography>

            </CardContent>
            <Divider />
            <CardContent>
            <div style={{ width: "100%", height: 600}} >
                <ResponsiveContainer>

                
                    <BarChart data={data}>
                        <XAxis />
                        <YAxis />
                        <Legend />
                        <Bar dataKey="you" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />}/>
                        <Bar dataKey="others" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />}/>
                        <Bar dataKey="all" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />}/>
                    </BarChart>
                </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}

const CompanyUsageAnalyticsCard = ({}): JSX.Element => {
    const [data, setData] = useState<CompanyUsageAnalytics[]>([])
    async function getCompanyUsageAnalytics(){
        try{
            const {data, }: AxiosResponse<CompanyUsageAnalyticsType[]> = await axiosInstance.get(`/analytics/company_usage/`);

            setData(data)
                  


        }catch(error){
            
        }
    }
    useEffect(()=>{
        getCompanyUsageAnalytics();
    }, [])
    // const exampleData = [
    //     {
    //         month: 'Feb',
    //         used: 10,
    //         not_used: 5
    //     },
    //     {
    //         month: 'Mar',
    //         used: 7,
    //         not_used: 8
    //     },
    //     {
    //         month: 'Apr',
    //         used: 15,
    //         not_used: 0
    //     },
    // ]
    return (
        <Card sx={{ mb: 1}}>
            <CardContent>
                <MDTypography variant="h3">
                    Account Usage
                </MDTypography>

            </CardContent>
            <Divider />
            <CardContent>
            <div style={{ width: "100%", height: 600}} >
            <ResponsiveContainer>
                <BarChart data={data}>
                    <XAxis dataKey="month"/>
                    <YAxis />
                    <Legend />
                    <Tooltip />
                    <Bar dataKey="used" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />}/>
                    <Bar dataKey="not_used" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />}/>
                </BarChart>
                </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}

const AdminAnalyticsCard = ({}): JSX.Element => {
    const [data, setData] = useState<AdminAnalytics[]>([])
    async function getAdminAnalytics(){
        try{
            const {data, }: AxiosResponse<AdminAnalyticsType[]> = await axiosInstance.get(`/analytics/admin/`);

            setData(data)
                  


        }catch(error){
            
        }
    }
    useEffect(()=>{
        getAdminAnalytics();
    }, [])
    return (
        <Card sx={{ mb: 1}}>
            <CardContent>
                <MDTypography variant="h3">
                    Response Times
                </MDTypography>

            </CardContent>
            <Divider />
            <CardContent>
            <div style={{ width: "100%", height: 600}} >
            <ResponsiveContainer>
                <ComposedChart data={data}>
                    <XAxis dataKey="month"/>
                    <YAxis />
                    <Legend />
                    <Tooltip />
                    <Area 
                        dataKey="range"
                        dot={false}
                        connectNulls
                        activeDot={false}
                    />
                    <Line type="natural" dataKey="avg" connectNulls />
                    <Line type="monotone" dataKey="median" connectNulls />
                </ComposedChart>
                </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}


const AnalyticsInner =({}): JSX.Element => {
    const { account } = useContext(AccountContext)
    
    return (
        <>
        <div className="row">
            <div className='col-6 col-xs-12'> 
                <UserConversationAnalyticsCard />
                
            </div>
            <div className='col-6 col-xs-12'> 
                <UserPromptAnalyticsCard />
                
            </div>
        </div>
            
            
            {account?.is_company_manager ? <CompanyUsageAnalyticsCard /> : <></>}
            {account?.email === "ryan+admin@aimloperations.com" ? <AdminAnalyticsCard /> : <></>}
            
        </>
    )
}

const AnalyticsPage = ({}): JSX.Element => {
    return (
        <PageWrapperLayout>
            <Header2 />
            <MDBox sx={{mt:12}}>
                
            </MDBox>
            <MDBox sx={{ margin: '0 auto', width: '80%', height: '80%', minHeight: '80%', maxHeight: '80%', align:'center'}}>
                <AnalyticsInner />
                <Footer />
            </MDBox>
        </PageWrapperLayout>
    )
}

export default AnalyticsPage;
